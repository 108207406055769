/**
 * 添加/移除元素的className
 * @param { Element } el 需要操作的元素
 * @param { String } className 需要操作的classname
 */
export default function(el, className) {
    if(!el || el.nodeType !== 1) return console.error('Invalid element')

    if(el.className.indexOf(className) !== -1) { // 移除该class
        const classList = Array.from(el.classList).filter(item => item !== className)
        el.className = classList.join(' ')
    } else { // 添加该class
        el.className = `${ el.className } ${ className }`
    }
}