// t=n+"//account.wps.cn/login?hide=wx&cb="+encodeURIComponent(n+"//dynamicedu.wps.cn/API_V2/login"+"?backUrl="+e.replace(/&/g,"@"));

/** 新版登录整理
 * 时间: 2020/09/25
 * 周岩
 * 注: 由于 @edu-fe/login 方法出现问题, 所以弃用, 后期会慢慢优化此处的登录, 以便于适用各个端, 最后发包
 */

 import axios from 'axios'

export default {

  loging () {
    // 此方法预留, 多端判断 并登录
  },

  // 网页浏览器 登录
  loginBrowse (url='') {
    const newUrl = encodeURIComponent(url ? url : window.location.href)
    const dynamiceduUrl = encodeURIComponent(`https://dynamicedu.wps.cn/API_V2/login?backUrl=${newUrl}`)
    const accountUrl = `https://account.wps.cn/login?hide=wx&cb=${dynamiceduUrl}`
    window.open(accountUrl, '_self')
  },

  // 获取当前用户的 userId  可以用户判断是否登录
  async getUserId () {
    axios.defaults.withCredentials=true
    const res = await axios.get('https://dynamicedu.wps.cn/API_V2/userid')
    if (res.data.code === 1) {
      return res.data.data
    } else {
      return false
    }
  },

  // 获取当前用户的 wpsSid
  async getUserWpsSid () {
    axios.defaults.withCredentials=true
    const res = await axios.get('https://dynamicedu.wps.cn/API_V2/wpsSid')
    if (res.data.code === 1) {
      return res.data.data
    } else {
      return false
    }
  },

}








