import os from '@edu-fe/os'

const invoke = (name, data = {}) => {
  data = JSON.stringify(data)
  data = data === '{}' ? '' : data

  return new Promise(async resolve => {
    if(window.wps_javascript) { // android
      const res = window.wps_javascript[name](data)
      resolve(JSON.parse(res))
    } else {
      const initJSBridge = () => new Promise(resolve => {
        if(window.WebViewJavascriptBridge) {
          resolve(window.WebViewJavascriptBridge)
        } else {
          document.addEventListener('WebViewJavascriptBridgeReady', () => {
            window.WebViewJavascriptBridge.init()
            resolve(window.WebViewJavascriptBridge)
          })
        }
      })

      const jsBridge = await initJSBridge()
      jsBridge.callHandler(name, data, res => resolve(JSON.parse(res)))
    }
  })
}

export const getInfomation = () => invoke('getInformation')

export default data => invoke('shareToWxUseClientUI', data)
