
export const format_filter = time => f => moment(time || new Date().getTime()).format(f)

// 毫秒转为时分秒展示
export const setDate = (date) => {
  const hours = parseInt(date / 1000 / 60 / 60)
  let mins = parseInt((date - hours * 1000 * 60 * 60) / 1000 / 60)
  if (mins < 10) {
    mins = '0' + mins
  }
  let secs = parseInt((date - hours * 1000 * 60 * 60 - mins * 1000 * 60) / 1000)
  if (secs < 10) {
    secs = '0' + secs
  }
  return `${hours}:${mins}:${secs}`
}

// 毫秒转为天时分秒展示
export const setDateAll = (date) => {
  const days = parseInt(date / (1000 * 60 * 60 * 24))
  let hours = parseInt((date % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  if (hours < 10) {
    hours = '0' + hours
  }
  let mins = parseInt((date % (1000 * 60 * 60)) / (1000 * 60))
  if (mins < 10) {
    mins = '0' + mins
  }
  let secs = parseInt((date % (1000 * 60)) / 1000)
  if (secs < 10) {
    secs = '0' + secs
  }
  return `${days}天${hours}时${mins}分${secs}秒`
}

export const delTime = (time) => {
  return time < 10 ? `0${time}` : time
}
