const isSupportWebp = (function () {
  try {
    return document.createElement('canvas').toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0
  } catch (err) {
    return false
  }
})()

export function resizeImage (src, param = { w: 500 }) {
  const ksyunReg = /http(s?):\/\/(v-sq\.ks3-cn-beijing\.ksyun\.com)\/.+\.(png|jpg|jpeg|webp)/g
  const cdnReg = /http(s?):\/\/(v[1-6]-edu\.wpscdn\.cn)\/.+\.(png|jpg|jpeg|webp)/g

  if (!cdnReg.test(src) && !ksyunReg.test(src)) {
    return src
  }

  const { w, h, q } = param

  const searchParams = new URLSearchParams()
  w && searchParams.set('w', w)
  h && searchParams.set('h', h)
  q && searchParams.set('q', q || 100) // 0-100
  isSupportWebp && searchParams.set('F', 'WEBP')

  if (searchParams.toString()) {
    src = `${src}@base@tag=imgScale&${searchParams.toString()}`
  }

  // 目前都是v1的图片，所以先前端处理分配v1-v6
  const cdnNum = src.match(/(\d)+/g).join('').slice(0, 6) % 6 + 1
  return src.replace('http:', 'https:').replace('v1-edu', 'v' + cdnNum + '-edu')
}

// console.log('===>>>', resizeImage('https://v4-edu.wpscdn.cn/image/ce440e8afa1f6a3ac16d69f3472f0065.jpg', {w:500}));
