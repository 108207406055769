// import eruda from 'eruda'
// eruda.init() // eslint-disable-line

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import rem from '../packages/rem' // eslint-disable-line
import utils from './lib/utils'
import VueLazyLoad from 'vue-lazyload'
import regenerationRuntime from 'regenerator-runtime'
import dw from './lib/dw'

import os from '@edu-fe/os'
import { refresh } from '@/mixins/isxxx'
import { refresh as refreshResourceVoucher } from '@/mixins/resourceVoucher.js'

window.regenerationRuntime = regenerationRuntime

Vue.use(dw)
Vue.config.ignoredElements = ['wx-open-launch-weapp']

Vue.use(VueLazyLoad, {
  preLoad: 1.3,
  loading: require('./assets/images/logo-seat.png'),
  attempt: 1
})

utils.getEnvEvent((res) => {
  const remOptions = {
    designWidth: 1920
  }

  if (res && res !== 'pc') {
    remOptions.designWidth = 750
    remOptions.maxWidthPx = Math.min(window.innerWidth, 480)
  }

  rem.set(remOptions)
})

// 监听小程序初始化成功
function ready () {
  if (window.__wpsoffice_environment === 'miniprogram') {
    console.log('WPS JSSDK 初始化成功，可以开始调用JSSDK API')
    const info = window.wps && window.wps.getAppInfo()
    if (info && info.appid) {
      // 刷新os和isxxx
      os.type = 'app'
      os.name = 'wpsmp'
      refresh()
      refreshResourceVoucher()
    }
  } else {
    console.log('WPS JSSDK 初始化失败')
  }
}

if (!window.wps) {
  document.addEventListener('WpsofficeSDKReady', ready, false)
} else {
  ready()
}

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
