export default [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/book-fission-list',
    name: 'BookFissionList',
    component: () => import(/* webpackChunkName: "book-fission" */ '../views/book-fission/list.vue')
  },
  {
    path: '/book-fission-address',
    name: 'BookFissionAddress',
    component: () => import(/* webpackChunkName: "book-fission" */ '../views/book-fission/address.vue')
  },
  {
    path: '/download-app',
    name: 'downloadApp',
    component: () => import(/* webpackChunkName: "download-app" */ '../views/download-app/DownloadApp.vue')
  },
  {
    path: '/pull-new',
    name: 'pullNew',
    meta: {
      title: '我花钱请你学'
    },
    component: () => import(/* webpackChunkName: "pull-new" */ '../views/pull-new/Index.vue')
  },
  {
    path: '/pull-new-share',
    name: 'pullNewShare',
    meta: {
      title: '我花钱请你学'
    },
    component: () => import(/* webpackChunkName: "pull-new" */ '../views/pull-new/Share.vue')
  },
  {
    path: '/first-lesson-new',
    name: 'first-lesson-new',
    meta: {
      title: 'WPS精品课第一课(新手礼包)'
    },
    component: () => import(/* webpackChunkName: "first-lesson-new" */ '../views/first-lesson-new/index.vue')
  }, {
    path: '/readingCard',
    name: 'readingCard',
    meta: {
      title: '读书打卡-疫情应急版'
    },
    component: () => import(/* webpackChunkName: "readingCard" */ '../views/readingCard/index.vue')
  }, {
    path: '/exchangeCode',
    name: 'exchangeCode',
    meta: {
      title: '兑换码页面'
    },
    component: () => import(/* webpackChunkName: "exchangeCode" */ '../views/exchangeCode/index.vue')
  }, {
    path: '/operational',
    name: 'operational',
    meta: { title: '竖点-训练营裂变活动' },
    component: {
      render () { return (<router-view></router-view>) }
    },
    children: [
      {
        path: '20200620/indexPC', // 精品课会员销售线索落地页 PC 端
        component: () => import(/* webpackChunkName: "operational" */ '../views/operational/20200620/indexPC.vue')
      }, {
        path: '20200803/indexPC', // 成长福利等级资源位PC页导流活动
        component: () => import(/* webpackChunkName: "operational" */ '../views/operational/20200803/indexPC.vue')
      }, {
        path: '20200909/indexPC', // 精品课会员销售线索落地页 PC 端 第四版
        component: () => import(/* webpackChunkName: "operational" */ '../views/operational/20200909/indexPC.vue')
      }, {
        path: '20201013/index', // WPS学院banner落地页活动 （ PC）
        component: () => import(/* webpackChunkName: "operational" */ '../views/operational/20201013/index.vue')
      }, {
        path: '20201210/index', //  训练营聚合页活动
        component: () => import(/* webpackChunkName: "act20201210" */ '../views/operational/20201210/index.vue')
      }, {
        path: '20201212/index', //  h5双十二活动
        component: () => import(/* webpackChunkName: "act20201212-h5" */ '../views/operational/20201212/index.vue')
      }, {
        path: '20201218/index', // 《好课限时抢》活动
        component: () => import(/* webpackChunkName: "act20201218-h5" */ '../views/operational/20201218/index.vue')
      }, {
        path: '20210114/index', // 《新年计划》活动
        component: () => import(/* webpackChunkName: "act20210114-h5" */ '../views/operational/20210114/index.vue')
      }, {
        path: '20210302/index', // 《华为右上角入口课程聚合页》活动  第四期
        component: () => import(/* webpackChunkName: "act20210302-h5" */ '../views/operational/20210302/index.vue')
      }, {
        path: '20210308/index', // 《精品课会员销售线索落地页》新版 (PC端)
        component: () => import(/* webpackChunkName: "act20210308-pc" */ '../views/operational/20210308/index.vue')
      }, {
        path: '20210308/indexWap', // 《精品课会员销售线索落地页》新版 (Wap端)
        component: () => import(/* webpackChunkName: "act20210308-pc" */ '../views/operational/20210308/indexWap.vue')
      }, {
        path: '20210311/index', //  应用页入口导流到APP活动页
        component: () => import(/* webpackChunkName: "act20210311-pc" */ '../views/operational/20210311/index.vue')
      }, {
        path: '20210315/index', //  会员促销活动  双端
        component: () => import(/* webpackChunkName: "act20210315-pc" */ '../views/operational/20210315/index.vue')
      }, {
        path: '20210325/index', //  健康管理师资格证 第二版
        component: () => import(/* webpackChunkName: "act20210325-pc" */ '../views/operational/20210325/index.vue')
      }, {
        path: '20210330/index', //  新人福利专题页 (产品需求 长期活动)
        component: () => import(/* webpackChunkName: "act20210330" */ '../views/operational/20210330/index.vue')
      }, {
        path: '20210409/index', //  可删除 测试各个端的跳转
        component: () => import(/* webpackChunkName: "act20210409" */ '../views/operational/20210409/index.vue')
      }, {
        path: '20210416/index', //  聚合页改版 第二版
        component: () => import(/* webpackChunkName: "act20210325-pc" */ '../views/operational/20210416/index.vue')
      }, {
        path: '20210427/index', //  应用页表格课程聚合页
        component: () => import(/* webpackChunkName: "act20210427-pc" */ '../views/operational/20210427/index.vue')
      }, {
        path: '20210428/index', //  华为瓦格纳活动
        component: () => import(/* webpackChunkName: "act20210428" */ '../views/operational/20210428/index.vue')
      }, {
        path: '20210521/index', //  稻壳会员支付成功跳转精品课活动页面
        component: () => import(/* webpackChunkName: "act20210521-pc" */ '../views/operational/20210521/index.vue')
      }, {
        path: '20210609/index', //  陪伴式学习引流活动
        component: () => import(/* webpackChunkName: "act20210609" */ '../views/operational/20210609/index.vue')
      }, {
        path: '20210630/index', //  稻壳求职加油站
        component: () => import(/* webpackChunkName: "act20210630" */ '../views/operational/20210630/index.vue')
      },
    ]
  }, { // PC端 iframe 活动
    path: '/iframeAct',
    name: 'iframeAct',
    meta: { title: 'PC端活动' },
    component: {
      render () { return (<router-view></router-view>) }
    },
    children: [
      {
        path: '20200728/index', // PC端新用户送会员活动
        component: () => import(/* webpackChunkName: "iframeAct" */ '../views/iframeAct/20200728/index.vue')
      },
      {
        path: '20200923/index', // PC端新用户送会员活动
        component: () => import(/* webpackChunkName: "iframeAct" */ '../views/iframeAct/20200923/index.vue')
      },
      {
        path: '20210220/index', // PC端 小米预装合作 活动
        component: () => import(/* webpackChunkName: "iframeAct" */ '../views/iframeAct/20210220/index.vue')
      }
    ]
  }, { // wpsApp 右侧卡片 跳转 落地页
    path: '/wpsRightCard',
    name: 'wpsRightCard',
    meta: { title: '内容详情' },
    component: {
      render () { return (<router-view></router-view>) }
    },
    children: [
      {
        path: 'pc/index',
        component: () => import(/* webpackChunkName: "wpsRightCard" */ '../views/wpsRightCard/pc/index.vue')
      }
    ]
  }
]
