/**
 * 自定义指令埋点上报，目前只是支持 曝光展示 功能
 * 页面内注册指令使用（最好在端内使用，API兼容性较差）
 * 
 * 这是一坨屎，本活动先将就用，后续再完善
 * 
 */
import utils from '@/lib/utils'
import os from '@edu-fe/os'
import getQueryString from '@/lib/getQueryString'
utils.loadJS('https://fe-edu.wpscdn.cn/assets/dw/dw-min-2.6.10.js')

const platform_id = os.type === 'weixin' ? 'wx_h5' : (os.type === 'app' && os.name === 'wps') ? 'wps_android' : (os.type === 'app' && os.name === 'wpsIos') ? 'wps_ios' : 'web_mobile'
const dwdebug = getQueryString(window.location.href, 'dwdebug') // dw调试
const entry_from = getQueryString(window.location.href, 'frpo')
const deviceKey = (os.type === 'app' && (os.name === 'wps' || os.name === 'wpsIos') && window.wps && window.wps.getSystemInfo) ? window.wps.getSystemInfo().deviceKey : ''

const setChannelByOrderfrom = (name = 'order_from') => {
  if (setChannelByOrderfrom.done) {
    return
  }
  if (window.location.search.includes(name)) {
    setChannelByOrderfrom.done = true
    window._dw.setChannel(getQueryString(window.location.href, name))
  }
}

const initDw = () => {
  if (window._dw) {
    // APPKEY 固定为同个值 [移动: b2ff7a6cf6c15c73] [PC: f86b735cf6923a32]
    let sppKey = 'b2ff7a6cf6c15c73' // 默认上报移动端
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) { // 移动端
      sppKey = 'b2ff7a6cf6c15c73'
    } else { // PC端
      sppKey = 'f86b735cf6923a32'
    }
    window._dw.setAppKey(sppKey) // 必须调用此方法，传入你产品的唯一标识
    window._dw.setChannel(platform_id)
    window._dw.setVersion(platform_id)
    window._dw.setCustomPublicAttrs('_client_id', deviceKey)
    
    // 在调试时开启为true,线上环境请务必开启为false
    if (process.env.NODE_ENV === 'development') {
      if (dwdebug === '1') {
        window._dw.setDebug(true)
      } else {
        window._dw.setDebug(false)
      }
    } else {
      if (dwdebug === '1') {
        window._dw.setDebug(true)
      } else {
        window._dw.setDebug(false)
      }
    }
  }
}

setTimeout(() => {
  initDw()
}, 500)

const options = {
  root: null, //默认浏览器视窗
  threshold: 1 //元素完全出现在浏览器视窗内才执行callback函数。
}

let timer = {} 

const callback =(entries, observer) => {
  entries.forEach(entry => {
    let visuallyData = null
    try {
      visuallyData = JSON.parse(entry.target.getAttribute('visually-data'))
      if (visuallyData.id === undefined) visuallyData.id = visuallyData.page_element
    } catch (e) {
      visuallyData = null
      console.error('埋点数据格式异常', e)
    }
    //没有埋点数据取消上报
    if (!visuallyData) {
      observer.unobserve(entry.target)
      return
    }
    if (entry.isIntersecting) {
      timer[visuallyData.page_element] = setTimeout(function() {
        //上报埋点信息
        if (window._dw) {
          // 内部公共参数
          const href = window.location.href
          const params = {
            entry_from: entry_from,
            platform_id: platform_id,
            _channel: platform_id,
            source_page_element: getQueryString(href, 'source_page_element'), // 上级来源
            page_attribute: 'edu_activity', // 页面属性, 区分活动 与 正常端内页面
            ...visuallyData
          }
          for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
              if (params[key] === undefined || params[key].length === 0) {
                delete params[key]
              }
            }
          }
          setChannelByOrderfrom()
          window._dw.onEvent('edu_page_show', params)
          observer.unobserve(entry.target);
          visuallyList.push(visuallyData.id);
          timer[visuallyData.id] = null;
        }
        
      }, 50)
  } else {
    if (timer[visuallyData.id]) {
      clearTimeout(timer[visuallyData.id])
      timer[visuallyData.id] = null
    }
  }
  })
}
const observer = new IntersectionObserver(callback, options)
let visuallyList = []; //记录已经上报过的埋点信息

const addListenner = (ele, binding) => {
  if(visuallyList.indexOf(binding.value) !== -1) return;
  observer.observe(ele)
}
const removeListener = (ele) => {
  observer.unobserve(ele)
}
export default  {
  bind: addListenner,
  unbind: removeListener
};