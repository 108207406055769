export default function (url, name) {
  // 如果中间带有code 从微信弹出来的
  const locationStr = url.split('?')
  let queryStr

  // 中间有两个 ? 很有可能从微信页签转过来的
  if (locationStr.length >= 3) {
    if (name === 'code' || name === 'state' || name === 'from') {
      queryStr = locationStr[1]
    } else {
      queryStr = locationStr[2]
    }
  } else {
    queryStr = locationStr[1]
  }
  if (typeof queryStr === 'undefined') {
    return ''
  }
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = queryStr.match(reg) // 获取url中"?"符后的字符串并正则匹配
  var context = ''
  if (r !== null) { context = r[2] }
  reg = null
  r = null
  return context === null || context === '' || context === 'undefined' ? '' : context
}
