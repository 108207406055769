import { isxxx } from './isxxx'

const { isWx, isIos } = isxxx

export const publicDownloadUrl = 'https://rubylink.cn/YlT1lY8KP'
export const iosAppStoreUrl = 'https://itunes.apple.com/app/apple-store/id1439039386?pt=38515&ct=wpsdownload&mt=8'

export default {
  data () {
    return {
      downloadList: {
        tz1: publicDownloadUrl,
        yyb11: publicDownloadUrl,
        yyb10: publicDownloadUrl,
        yyb9: publicDownloadUrl,
        yyb8: publicDownloadUrl,
        yyb7: publicDownloadUrl,
        yyb5: publicDownloadUrl,
        yyb4: publicDownloadUrl,
        yyb3: publicDownloadUrl,
        yyb2: publicDownloadUrl,
        yyb1: publicDownloadUrl,
        vc12_yyb1: publicDownloadUrl
      }
    }
  },
  methods: {
    download (type) {
      if (isIos) {
        return (window.location.href = iosAppStoreUrl)
      }
      if (isWx) {
        window.location.href = `https://edu-m.wps.cn/download-app?type=${type}&frpo=${this.$route.query.frpo}`
        return
      }
      if (this.downloadList[type]) {
        return (window.location.href = this.downloadList[type])
      }
      // window.location.href = 'http://staticedu.wps.cn/app/app-develop-v1.5.0-code18-2020-01-16_17.59.59-default-yingyonghui-release.apk'
      window.location.href = publicDownloadUrl
    }
  }
}
