/**
 * 使用interval创建滚动动画
 * 只支持y轴滚动
 * @param { Element } elScroller 可以滚动的元素
 * @param { Number } scrollTarget 滚动的目标位置
 * @param { Number } duration 动画时长
 * @param { Promise }
 */
export default function(elScroller, scrollTarget, duration = 1000) {
    if(!elScroller) return console.error('Invalid scroller element') // eslint-disable-line
    if(!scrollTarget) return console.error('Invalid scroll target') // eslint-disable-line

    const currentScrollTop = elScroller.scrollTop

    /**
     * 滚动方向
     * @value
     * 1: 向下
     * 0: 向上
     */
    const direction = (scrollTarget > currentScrollTop) ? 1 : 0
    const distance = Math.abs(scrollTarget - currentScrollTop) // 总共需要滚动的距离
    const interval = 10 // 每次滚动的间隔
    const totalCount = Math.ceil(duration / interval) // 总共滚动的次数
    const eachDistance = Math.floor(distance / totalCount) // 每次滚动的距离

    const p = new Promise(resolve => {
        let counter = 0
        const timer = setInterval(() => {
            if(direction) {
                elScroller.scrollTop += eachDistance
            } else {
                elScroller.scrollTop -= eachDistance
            }
            counter++

            if(counter === totalCount) {
                clearInterval(timer)
                resolve()
            }
        }, interval)
    })

    return p
}