export class StyleTransfer {
  constructor (globalWidth, globalRemScale) {
    this.globalWidth = globalWidth
    this.globalRemScale = globalRemScale
  }

  isNumber (arg) {
    if (typeof arg === 'number') {
      return true
    }
    if (String(parseFloat(arg)) === arg) {
      return true
    }
    return false
  }
  parseStyle (result, value, name, unit) {
    if (!value) return
    if (this.isNumber(value)) {
      value = parseFloat(value)
      if (unit === 'rem') {
        result[name] = value / this.globalWidth * this.globalRemScale + 'rem'
      } else {
        result[name] = value + '.' + unit
      }
    } else {
      result[name] = value
    }
  }
}
