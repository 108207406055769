/**
 * 区分2016/2019wps客户端api
 * 调用客户端api为异步操作
 * @return Promise
 */
 const api2016 = {
  version: '2016',
  checkLogin: 'qingCheckLogined',
  login: 'qingLogin',
  logout: 'qingLogout',
};

const api2019 = {
  version: '2019',
  checkLogin: 'common.account.checkLoginState',
  login: 'common.account.login',
  logout: 'common.account.logout',
};

const p = new Promise((resolve, reject) => {
  window._getClientBrowserInfo = (res) => {
    res = JSON.parse(atob(res));
    console.log(res)
    if (res.type.indexOf('2019') !== -1) { // 2019版本
      resolve(api2019);
    } else {
      resolve(api2016);
    }
  };

  // 判断客户端版本
  const Base64JSONString = window.btoa(JSON.stringify({
    method: 'common.util.getBrowserInfo',
    callback: '_getClientBrowserInfo',
  }));

  setTimeout(() => {
    try {
      const _cefQuery = window.parent.cefQuery || window.parent.wpsQuery
      if (window.parent.homepageapiasync || window.parent.homepageapi) { // 更老的版本
        resolve(api2016);
      } else if (_cefQuery) { // 2016、2019版本
        _cefQuery({
          request: `fromJSAsynCallBase64("${Base64JSONString}")`,
          persistent: false,
          onSuccess(response) {},
          onFailure(code, msg) {
            resolve(api2016);
          },
        });
      }
    } catch (e) {
      console.log('...');
      resolve(api2016);
    }
  }, 500);
});

export default p;
