import os from '@edu-fe/os'

export default function () {
  // APPKEY 对应的平台取值：
  // [edu ios: 17b81b14418243d5]
  // [edu android: 5c40886a6982c70f]
  // [H5: b2ff7a6cf6c15c73] 
  // [PC: f86b735cf6923a32]
  let appKey;
  if (os.type === 'weixin' || (os.type === 'app' && (os.name === 'wps' || os.name === 'wpsIos'))) {
    appKey = 'b2ff7a6cf6c15c73'
  } else if (os.type === 'app' && os.name === 'ios' || (/JpkClientIOS/i.test(window.navigator.userAgent))) {
    appKey = '17b81b14418243d5'
  } else if ((os.type === 'app' && os.name === 'android') || (/JpkClientAndroid/i.test(window.navigator.userAgent))) {
    appKey = '5c40886a6982c70f'
  } else {
    appKey = 'f86b735cf6923a32'
  }
  return appKey
}