
import execService from '@wps/wps_client_api/exec/index'
import accountEvent from '@wps/wps_client_api/account'
import asyncClientApi from './clientApiMap';

import login from '@/lib/newLogin'
import axios from 'axios'
import { isxxx } from '@/mixins/isxxx'
import os from '@edu-fe/os'
const wpsadc = require('@/lib/wpsadc.min.js')

const getUrl = `https://dynamicedu.wps.cn`

const localOs = {
  type: '', // window-wpsApp | mac-wpsApp
  name: '',
  call(json, callback) { // 调用
    // json.method 调用方法名称
    if (!json.method) {
      return console.log('[os] error: attribute \'method\' needed!');
    }
    if (typeof callback === 'function') {
      // 兼容2019api，将 . 做替换，否则调用全局callback报错
      json.callback = `wps${json.method}Callback`.replace(/\./g, '_');
      try {
        window.parent[json.callback] = callback;
      } catch(e) {
        console.warn(e);
      }
    }
    this.core(json);
  },
  core: null, // 核心方法
  loginUrl: `${window.location.protocol}//dynamicedu.wps.cn/API_V2/login?backUrl=`,
};

export default {
  /** 获取当前环境 !!! 不再使用 !!!
     *  因为 暂时还有活动在使用, 不可删除, 但是以后 不再使用!
    */
  async getEnvEvent (back) {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      var iswx = navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
      if (iswx) { // 微信中
        try {
          wx.miniProgram.getEnv((res) => {
            if (res.miniprogram) {
              back('wxpro') // 微信小程序内
            } else {
              back('wx') // 微信页面(微信浏览器打开)
            }
          })
        } catch (e) {
          back('wx') // 微信页面(微信浏览器打开)
        }
      } else { // 非微信内
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.handleReq) {
          back('wapApp')
        } else if (window.splash) { // 安卓 App 内
          back('wapApp')
        } else {
          back('wap')
        }
      }
    } else { // PC 端
      try{
        !window.parent.cefQuery && window.parent.wpsQuery && execService.setQueryKey('wpsQuery')
        execService.exec('common.wpsoffice.getAppInfo', {}, (err, res) => {
          if (res && res.appname === 'wps') {
            back('pcwps')
          }else{
            back('pc')
          }
        })
      }catch(err){
        back('pc')
      }
    }
  },

  /** 判断当前运行环境 【 新版 】
   * 参数:
   *  移动端 (ios + android)
   *    app:    精品课App
   *    wps:    wpsApp
   *    wpsMp:  wpsApp 小程序
   *    wx:     微信
   *    wxMp:   微信小程序
   *    wap:    移动端浏览器
   *  PC端: (window + mac)
   *    pc:     pc端浏览器
   *    pcWps:  pc端 wps 客户端
  */
  async getNowEnv (back) {
    let envName = ''
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      // 精品课 App 内
      if (isxxx.isInApp) { envName = 'app' }
      // wpsApp 内
      else if (isxxx.isInWps) { envName = 'wps' }
      // wpsApp 小程序内
      else if (isxxx.isWpsAndroidMp || isxxx.isWpsIosMp) { envName = 'wpsMp' }
      // 微信内
      else if (isxxx.isWx) {
        try {
          wx.miniProgram.getEnv((res) => {
            if (res.miniprogram) { envName = 'wxMp' }
            else { envName = 'wx' }
          })
        } catch (e) { envName = 'wx' }
      }
      // 普通浏览器
      else { envName = 'wap' }
      back(envName)
    } else {
      try {
        this.setQueryKey()
        const IS_MAC = /macintosh|mac os x/i.test(navigator.userAgent);
        const oldApi = window.parent.homepageapiasync || window.parent.homepageapi;
        const newApi = window.parent.cefQuery || window.cefQuery || window.parent.wpsQuery || window.wpsQuery;

        if (oldApi) {
          localOs.type = 'wps-pc'
          localOs.core = function (json) {
            const jsonString = JSON.stringify(json);
            oldApi.jsAsynCall(jsonString);
          };
        } else if (newApi) {
          localOs.type = 'wps-pc'
          localOs.core = function (json) {
            // 首先尝试调用2019api，若失败则调用2016api
            const Base64JSONString = window.btoa(JSON.stringify(json));
            newApi({
              request: `fromJSAsynCallBase64("${Base64JSONString}")`,
              persistent: false,
              onSuccess(response) {
              },
              onFailure(code, msg) {
                if (code === -2) { // 2016调用2019api "Method Not Found"
                  const jsonString = JSON.stringify(json);
                  newApi({
                    request: `jsAsynCall("${jsonString}")`,
                    persistent: false,
                    onSuccess(response) {
                    },
                    onFailure(code, msg) {},
                  });
                }
              }
            })
          }
        }

        await localOs.call({
          method: 'common.wpsoffice.getAppInfo'
        }, (res) => {
          const appInfo = JSON.parse(window.atob(res));
          if (['wpsoffice', 'wps'].indexOf(appInfo.appname) !== -1) {
            if(localOs.type === 'wps-pc' && IS_MAC){
              back('pcWpsMac')
            } else {
              back('pcWpsWin')
            }
          } else {
            back('pc')
          }
        })
        // execService.exec('common.wpsoffice.getAppInfo', {}, (err, res) => {
        //   console.log('panduan shifou wpsApp===>', res);
        //   if (res && res.appname === 'wps') { envName = 'pcWps' }
        //   else { envName = 'pc' }
        //   back(envName)
        // })
      } catch (e) {
        envName = 'pc'
        back(envName)
      }
    }
  },
  setQueryKey () {
    !window.parent.cefQuery && window.parent.wpsQuery && execService.setQueryKey('wpsQuery')
  },

  /** 返回 os 内容 */
  getOs () {
    return os
  },

  /** 判断当前移动设备类型 (ios | android) **/
  equipmentType () {
    var type = ''
    if (/android/i.test(navigator.userAgent)) {
      type = 'android'
    }
    if (/ipad|iphone|mac/i.test(navigator.userAgent)) {
      type = 'ios'
    }
    return type
  },

  /**
   * 获取 精品课 App 版本号
   */
  async getJPKAppVersion (back) {
    let appInfo = ''
    if (isxxx.isAppIos) {
      appInfo = JSON.parse(await os.sdk.use('getInformation')).v
    } else {
      appInfo = JSON.parse(window.wps_javascript.invokeOsSystem()).versionName
    }
    back(appInfo)
  },

  /**
   *   App 版本比较
   * @param {*} val1 比较对象
   * @param {*} val2 需要比较的对象
   * @returns true: val1 <= val2   false: val1 > val2
   */
  ediTionCompare (val1, val2) {
    const valA = val1.split(".")
    const valB = val2.split(".")
    for (let i = 0; i < valA.length; i++) {
      if (valA[i] > valB[i]) {
        return false
      } else if (valA[i] < valB[i]) {
        return true
      } else {
        if (i === (valA.length - 1)) {
          return true
        }
      }
    }
  },

  /**
   * 返回图片的 base64 数据
   * @param {*} url 图片路径
   * @param {*} ext 图片格式
   * @param {*} callback
   */
  getUrlBase64 (url, ext, callback) {
    var canvas = document.createElement('canvas') // 创建canvas DOM元素
    var ctx = canvas.getContext('2d')
    var img = new Image()
    img.crossOrigin = 'Anonymous'
    img.src = url
    img.onload = function () {
      canvas.height = img.height // 指定画板的高度,自定义
      canvas.width = img.width // 指定画板的宽度，自定义
      ctx.drawImage(img, 0, 0, img.width, img.height) // 参数可自定义
      var dataURL = canvas.toDataURL('image/' + ext)
      callback.call(this, dataURL) // 回调函数获取Base64编码
      canvas = null
    }
  },

  /**
   * 返回 url 链接 ? 后面的参数
   * @param {*} link 可以传入 url, 不传递默认获取当前页面 url
   */
  getUrlRequest (link = '') {
    // var url = link ? link : location.search // 获取url中"?"符后的字串
    var url = link ? ('?' + decodeURIComponent(link).match(/([^?]+)$/)) : location.search // 获取url中"?"符后的字串
    var theRequest = new Object()
    if (url.indexOf('?') !== -1) {
      var str = url.substr(1)
      if (str.indexOf('&') !== -1) {
        var strs = str.split('&')
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1]);
        }
      } else {
        var key = str.substring(0, str.indexOf('='))
        var value = str.substr(str.indexOf('=') + 1)
        theRequest[key] = decodeURI(value)
      }
    }
    return theRequest
  },

  // 时间戳转化 返回 年月日 时分秒
  getTimeArr (time) {
    var date = new Date(parseInt(time))
    var timeData = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      date: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds()
    }
    return timeData
  },

  /** 存储数据到 localStorage.setItem */
  setStorage (name, data = {}) {
    window.localStorage.setItem(name, JSON.stringify(data))
  },
  /** 取出数据 localStorage.getItem */
  getStorage (name) {
    const data = window.localStorage.getItem(name) || ''
    return (data && data !== 'undefined') ? JSON.parse(window.localStorage.getItem(name)) : false
  },
  /** 删除 localStorage */
  removeStorage (name) {
    window.localStorage.removeItem(name)
  },
  /** 清空 localStorage */
  clearStorage () {
    window.localStorage.clear()
  },


  /** 计算一天的 剩余时间 */
  oneDayRemainingTime (data) {
    const hour = 23 - data.hour
    const minute = 59 - data.minute
    const second = 59 - data.second
    const allSecond = hour * 3600 + minute * 60 + second
    return {
      hour,
      minute,
      second,
      allSecond
    }
  },

  /** 倒计时方法 */
  CountdownAnime (secondNum) {
    const days = Math.floor(secondNum / 86400)
    const hour = Math.floor((secondNum - days * 86400) / 3600)
    const minute = Math.floor((secondNum - days * 86400 - hour * 3600) / 60)
    const second = secondNum - days * 86400 - hour * 3600 - minute * 60

    let timeAry = []
      timeAry[0] = parseInt(hour/ 10) || 0
      timeAry[1] = parseInt(hour % 10) || 0
      timeAry[2] = parseInt(minute / 10) || 0
      timeAry[3] = parseInt(minute % 10) || 0
      timeAry[4] = parseInt(second / 10) || 0
      timeAry[5] = parseInt(second % 10) || 0
    return {
      days,
      hour,
      minute,
      second,
      timeAry
    }
  },

  /** 生成 UUID 方法 */
  createUUID () {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
    let uuid = new Array(36),
        rnd = 0,
        r
    for (let i = 0; i < 36; i++) {
        if (i === 8 || i === 13 || i === 18 || i === 23) {
            uuid[i] = '-'
        } else if (i === 14) {
            uuid[i] = '4'
        } else {
            if (rnd <= 0x02)
                rnd = 0x2000000 + (Math.random() * 0x1000000) | 0
            r = rnd & 0xf
            rnd = rnd >> 4
            uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
        }
    }
    return uuid.join('')
  },

  /** 监听页面是否可见
   * _this: this
   * funcShow: 页面显示时 需要执行的方法
   * funcHide: 页面隐藏时 需要执行的方法
  */
  setupMedia (_this, funcShow='', funcHide='') {
    let hidden, state, visibilityChange;
    if (typeof document.hidden !== "undefined") {
        hidden = "hidden";
        visibilityChange = "visibilitychange";
        state = "visibilityState";
    } else if (typeof document.mozHidden !== "undefined") {
        hidden = "mozHidden";
        visibilityChange = "mozvisibilitychange";
        state = "mozVisibilityState";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
        state = "msVisibilityState";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
        state = "webkitVisibilityState";
    }

    document.addEventListener(visibilityChange, ()=>{
      if (document[state] === 'visible') { // 页面显示
        if(funcShow){
          _this[funcShow]()
        }
      } else { // 页面切换到后台
        if (funcHide) {
          _this[funcHide]()
        }
      }
    }, false)
  },

  // 页面内动态加入js
  loadJS (url) {
    var domscript = document.createElement('script');
    domscript.src = url;
    document.getElementsByTagName('head')[0].appendChild(domscript);
  },

  /** 设置当前页面 title */
  setPageTitle(actTitle=''){
    document.getElementsByTagName('title')[0].innerText = actTitle
  },

  /** 页面内 设置 .ico 图标 */
  setPageIco (icoUrl = '') {
    icoUrl = icoUrl ? icoUrl : require('@/assets/images/favicon.png')
    var newUrl = /http(s)?:\/\//gi.test(icoUrl) ? require(`${icoUrl}`) : icoUrl;
    var link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = newUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  },

  // 跳转微信 打开客服聊天
  launchWxApp (obj) {
    const appId = obj.appId ? obj.appId : 'gh_5a735452104f'
    const wxMiniPath = obj.wxMiniPath ? obj.wxMiniPath : `pages/signin/index`
    if (window.wps && window.wps.launchWechatMiniProgram) {
      window.wps.launchWechatMiniProgram({
        wxMiniAppID: appId,
        wxMiniPath: wxMiniPath
      })
    } else {
      wpsadc.startWxApp({
        'mini_program_id': appId, // 小程序原始ID
        'mini_program_userName': appId, // 小程序原始ID
        'mini_program_path': wxMiniPath, // 小程序路径
        'mini_program_type': obj.type ? obj.type : 0 // 正式版0, 测试版1, 体验版2
      }) // 调起小程序
    }
  },

  // =========================================
  /** 公用的 axios 方法 */
  async AxiosEvent (obj) {
    if (isxxx.isInApp) {
      const wpsSid = await os.sdk.user.get() || ''
      let loginToken = ''
      if (isxxx.isAppAndroid) {
        loginToken = await os.sdk.user.getToken() || ''
      }
      if (obj.method && obj.method.toLowerCase() === 'post') {
        obj.url = obj.url + (obj.url.indexOf('?') === -1 ? '?' : '&') + (wpsSid ? `wpsSid=${wpsSid}&` : ``) + (loginToken ? `loginToken=${loginToken}` : ``)
      } else {
        if(!obj.params) {
          obj.params = {}
        }
        obj.params.wpsSid = wpsSid
        obj.params.loginToken = loginToken
      }
    }
    return await axios({
      method: obj.method || 'get',
      url: obj.url.indexOf('http') !== -1 ? obj.url : getUrl + obj.url,
      headers: obj.headers || {},
      params: obj.params || {},
      data: obj.data || {},
      withCredentials: true
    })
  },


  // =========================================
  /** 判断是否登录 */
  async isLoginType (back) {
    await this.getNowEnv(async (type) => {
      // wpsApp
      if (['wps', 'wpsMp'].indexOf(type) !== -1) {
        await window.wps.isLogin({
          complete: (res) => {
            back(res.data.isLogin || res.data.is_login)
          }
        })
      }
      // 精品课App
      else if (type === 'app') {
        if (isxxx.isAppAndroid) {
          back(window.wps_javascript.isLogin())
        } else {
          const sdk = await os.sdk.user.get()
          if (sdk) {
            back(true)
          } else {
            back(false)
          }
        }
      }
      // wap + pc
      else {
        let isUserId = await login.getUserId()
        if (isUserId) {
          back(true)
        } else {
          back(false)
        }
      }
    })
  },

  /** PC端 wpsApp 注册事件 */
  intervalCheck () {
    let count = 0;
    let checkFlag;
    let osTimeName = ''
    asyncClientApi.then((clientApi) => {
      clearInterval(osTimeName)
      osTimeName = setInterval(() => {
        localOs.call({ method: clientApi.checkLogin }, (res) => {
          let data;
          if (clientApi.version === '2019') {
            res = JSON.parse(atob(res));
            res.result = `['${res.callstatus}','${res.isLogined}','${res.wpssid}','${JSON.stringify({ userid: res.userId })}']`;
            data = res.result.split(',');
            if (!count) {
              checkFlag = data;
              count++;
            }
          } else {
            data = JSON.parse(res).result.split(',');
            if (!count) {
              checkFlag = data;
              count++;
            }
          }
          if (data[1] && data[1] !== checkFlag[1]) {
            window.location.reload();
          }
        })
      }, 1000)
    })
  },

  /** 调用登录
   * 注意: 安卓wpsApp 不会返回用户信息
   * */
  async gotoLogin (back) {
    await this.getNowEnv(async (type) => {
      // wps + wpsApp
      if (['wps', 'wpsMp'].indexOf(type) !== -1) {
        await window.wps.login({
          complete: (res) => {
            // 不管登录是否成功 都会返回 res
            res.data.isLogin = res.data.isLogin || res.data.is_login
            back(res.data)
          }
        })
      }
      // PC 端 Wps
      else if (['pcWpsWin', 'pcWpsMac'].indexOf(type) !== -1) {
        this.intervalCheck()
        await localOs.call({ method: accountEvent.login({source: 'test', extraParams: {qrcode: 'wpsedu'}}) }, () => {
          console.log('重新加载页面');
        });
        // clientApi.account.login()
        // accountEvent.addLoginListener((res)=>{
        //   if (res.callstatus === 'ok' && res.wpssid) {
        //     res.isLogin = true
        //   } else {
        //     res.isLogin = false
        //   }
        //   back(res)
        //   accountEvent.removeLoginListener()
        // })
      }
      // 精品课App android + ios
      else if (type === 'app') {
        if (isxxx.isAppAndroid) {
          back(window.wps_javascript.onLogin())
        } else {
          back(os.sdk.user.login())
        }
      }
      // 微信 + 微信小程序
      else if (['wx', 'wxMp'].indexOf(type) !== -1) {
        const skip = this.getUrlRequest().skip || ''
        let nowUrl = window.location.href
        let newUrl = nowUrl + (nowUrl.indexOf('?') !== -1 ? '&' : '?') + (skip ? '' : 'skip=wxlogin')
        window.location.href = 'https://dynamicedu.wps.cn/API_V2/weixin/url/userinfo/v2?rd=' + encodeURIComponent(newUrl)
      }
      // 浏览器
      else {
        return login.loginBrowse()
      }
    })
  },

  /** 获取用户登录信息 */
  async userInfo (back) {
    await this.getNowEnv(async (env) => {
      if (['wps', 'wpsMp'].indexOf(env) !== -1) {
        // wpsApp 安卓端
        if (isxxx.isWpsAndroid || isxxx.isWpsAndroidMp) {
          wpsadc.requestSession({ userInfo: true }).then(res => {
            if (res.userInfo) {
              back(this.disposeUserInfo(res.userInfo))
            }
          })
        }
        // wpsApp ios 端
        else {
          window.wps.getUserInfo({
            complete: (res) => {
              if (res.code === 0) {
                back(this.disposeUserInfo(res.userInfo))
              }
            }
          })
        }
      } else {
        let wpsSid = ''
        if (isxxx.isAppIos || isxxx.isAppAndroid) {
          wpsSid = await os.sdk.user.get()
        } else {
          wpsSid = await login.getUserWpsSid()
        }
        await this.AxiosEvent({
          url: `/API_V2/user_info`,
          params: {
            wpsSid: wpsSid
          }
        }).then((res) => {
          back(this.disposeUserInfo(res.data.data))
        }).catch((err) => {
          console.log('公用方法获取 userInfo err =>', err)
        })
      }
    })
  },
  /** 用户信息整理统一化 (解决 网页 与 wps 返回的字段名不一致 问题) */
  disposeUserInfo (res) {
    const newRes = {
      picUrl: res.picUrl || res.pic,
      userId: res.userId || res.userid,
      ...res
    }
    return newRes
  }


}
