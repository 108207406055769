import { isxxx } from '@/mixins/isxxx'
// import { voucher } from '@/mixins/resourceVoucher'
import { publicDownloadUrl, iosAppStoreUrl } from '@/mixins/downloadApp'

const ANDRIOD_APP_HOME_URL = 'main://com.wps.excellentclass'
const ANDRIOD_APP_COURSE_URL_FN = (courseId) => `courseid://com.wps.excellentclass?id=${courseId}`

const IOS_APP_HOME_URL = 'KSCBTanZhi://'
// 跳转到app上对应课程详情，是一个方法
const IOS_APP_COURSE_URL_FN = (courseId) => ('KSCBTanZhi://2*' + encodeURIComponent(`{"vc":"KSNewCourseDetailViewController","courseId":"${courseId}","buy_path":"banner"}`))

// in wps
// const WPS_DOWNLOAD_APP_HOME_URL = `wpsoffice://wps.cn/web?type=browser&url=${encodeURIComponent('https://edu-m.wps.cn/download-app?appPath=home&route=1')}`
// in wps   ps: 这是个方法
// const WPS_DOWNLOAD_APP_COURSE_URL_FN = courseId => `wpsoffice://wps.cn/web?type=browser&url=${encodeURIComponent('https://edu-m.wps.cn/download-app?appPath=detail&route=1&courseId=' + courseId)}`
// wps 调起浏览器 打开对应链接
const WPS_OPEN_BROWSER = 'wpsoffice://wps.cn/web?type=browser&url='
// edu-m 下载页面链接
const EDU_M_DOWNLOAD_URL = 'https://edu-m.wps.cn/download-app?route=1'

const hidden = function (timer) {
  const doc = document
  var hiddenProperty =
    'hidden' in doc
      ? 'hidden'
      : 'webkitHidden' in doc
        ? 'webkitHidden'
        : 'mozHidden' in doc
          ? 'mozHidden'
          : 'msHidden' in doc
            ? 'msHidden'
            : ''

  if (hiddenProperty) {
    var visibilityChangeEvent = hiddenProperty.replace(
      /hidden/i,
      'visibilitychange'
    )
    var onVisibilityChange = function () {
      if (doc[hiddenProperty]) {
        // 隐藏
        clearTimeout(timer)
        doc.removeEventListener(visibilityChangeEvent, onVisibilityChange)
      }
    }
    doc.addEventListener(visibilityChangeEvent, onVisibilityChange)
    setTimeout(() => {
      doc.removeEventListener(visibilityChangeEvent, onVisibilityChange)
    }, 5000)
  } else {
    window.onblur = function () {
      clearTimeout(timer)
    }
  }
}

const tryToOpenApp = (appUrl, downloadAppUrl, autoOpen, autoDownload, openUrl) => {
  if (autoOpen && autoDownload) {
    // 尝试打开app
    window.location = appUrl
    const timer = setTimeout(() => {
      // 2s无反应，跳去下载app
      if (isxxx.isWpsAndroid) {
        const pageUrl = openUrl ? openUrl : EDU_M_DOWNLOAD_URL
        window.location.href = `${WPS_OPEN_BROWSER}${encodeURIComponent(pageUrl)}`
      } else {
        window.location.href = downloadAppUrl
      }
    }, 2000)
    // 2s内有反应，删除timer
    hidden(timer)
  } else if (!autoOpen && autoDownload) {
    // 自动下载app
    window.location.href = downloadAppUrl
  } else if (autoOpen && !autoDownload) {
    // 自动尝试打开app
    window.location = appUrl
  }
}

const openApp = ({ ios, android, autoOpen, autoDownload, openUrl}) => {
  if (isxxx.isIos) {
    tryToOpenApp(ios, iosAppStoreUrl, autoOpen, autoDownload, openUrl)
  } else {
    tryToOpenApp(android, publicDownloadUrl, autoOpen, autoDownload, openUrl)
  }
}


// 跳转首页
export function openAppHome ({ autoOpen = true, autoDownload = true, openUrl = '' }) {
  openApp({ ios: IOS_APP_HOME_URL, android: ANDRIOD_APP_HOME_URL, autoOpen, autoDownload, openUrl })
}

// 跳转指定课程页面
export function openAppCourse ({ courseId, autoOpen = true, autoDownload = true, openUrl = '' }) {
  openApp({ ios: IOS_APP_COURSE_URL_FN(courseId), android: ANDRIOD_APP_COURSE_URL_FN(courseId), autoOpen, autoDownload, openUrl })
}
