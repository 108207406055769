import api from '@edu-fe/api'
import * as get from '@ccforeverd/tool-get'

import login from './login'

let wpsSidCache = ''
api.use(async (options, next) => {
  try {
    return login.getWpsSid().then(wpsSid => {
      wpsSid = wpsSid || wpsSidCache
      options.params = options.params || {}
      wpsSidCache = wpsSid
      // 目前 api 的配置聚合方式有问题，所以先用正则进行简单判断是否自带 wpsSid
      if (!/wpsSid=/.test(options.url)) {
        options.params.wpsSid = wpsSid
      }

      options.params.loginToken = wpsSid || ''
      options.params.channel = get.data.channel || ''

      return next()
    })
  } catch (e) {
    console.log(e)
  }
})

export default api
