/**
 * 说明：dw的封装，包含dw初始化__init，埋点通用信息setParams
 * 需在index.html中引入https://fe-edu.wpscdn.cn/assets/dw/dw-min-2.6.10.js
 */


import os from '@edu-fe/os'
import * as get from '@ccforeverd/tool-get'
import getAppKey from '@/lib/getAppKey'

const platform_id = os.type === 'weixin'
  ? 'wx_h5' // 微信
  : (os.type === 'app' && os.name === 'wps')
    ? 'wps_android' // wps安卓
    : (os.type === 'app' && os.name === 'wpsIos')
      ? 'wps_ios' // wpsIos
      : (os.type === 'app' && os.name === 'android')
        ? 'edu_android' // 精品课安卓端
        : (os.type === 'app' && os.name === 'ios')
          ? 'edu_ios' // 精品课iOS端
          : 'web_mobile' // 其他


function __init(name = 'frpo') {
  // setCustomPublicAttrs:_client_id
  const deviceKey = (os.type === 'app' && (os.name === 'wps' || os.name === 'wpsIos') && window.wps && window.wps.getSystemInfo) ? window.wps.getSystemInfo().deviceKey : ''
  window._dw.setCustomPublicAttrs('_client_id', deviceKey)

  // setChannel
  if (window.location.search.includes(name)) {
    window._dw.setChannel(get.query()[name])
  }

  // setVersion
  window._dw.setVersion(platform_id)

  // seAppKey
  window._dw.setAppKey(getAppKey())

  // setDebug 在调试时默认开启为true, 线上环境请务必开启为false
  if (process.env.NODE_ENV === 'development') {
    if (get.data.dwdbg === '0') {
      window._dw.setDebug(false)
    } else {
      window._dw.setDebug(true)
    }
  }
}

function setParams(params = {}) {
  // 内部公共参数
  const commonParams = {
    entry_from: get.data.frpo || '',
    platform_id: get.data.orderfrom || platform_id, // platform_id 不能为空
    source_page_element: get.data.source_page_element,
  }

  params = {
    ...commonParams,
    ...params
  }
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      if (params[key] === undefined || params[key] === null || (typeof params[key] === 'string' && params[key].trim() === '')) {
        delete params[key]
      }
    }
  }
  return params
}

function __dw(name, params = {}) {
  if (window._dw) {
    window._dw.onEvent(name, setParams(params))
  } else {
    console.warn('v-dw error:未找到dw，请确认dw jssdk是否已正常加载')
  }
}

let timer = {}
const dwList = {}
const options = {
  root: null, //默认浏览器视窗
  threshold: 0.0 //默认 元素1px出现在浏览器视窗内就执行callback函数，如设置1则表示完成出现，0.5表示出现一半。
}

const callback = (entries, observer) => {
  entries.forEach(entry => {
    let dwData = null
    let dwDataId = entry.target.dataset.dwid
    if (dwDataId) {
      try {
        dwData = dwList[dwDataId]['data']
      } catch (e) {
        dwData = null
        console.warn('v-dw error:埋点数据格式异常', e)
      }
    }

    //没有埋点数据取消上报
    if (!dwData) {
      observer.unobserve(entry.target)
      return
    }

    if (entry.isIntersecting) {
      timer[dwDataId] = setTimeout(function () {
        //上报埋点信息
        if (window._dw) {
          // 内部公共参数
          const params = {
            _channel: platform_id,
            page_attribute: 'edu_activity', // 页面属性, 区分活动 与 正常端内页面
            ...dwData
          }
          window._dw.onEvent('edu_page_show', setParams(params))
          observer.unobserve(entry.target);
          delete dwList[dwDataId]
          delete timer[dwDataId]
        }
      }, 1)
    }
  })
}

const dw = {
  install: function (Vue) {
    if (window._dw) {
      __init()
    }
    Vue.prototype.$dw = __dw

    // 自定义全局指令 v-dw
    Vue.directive('dw', {
      bind: function (ele, binding) {
        if (binding.value && binding.value.page_name && binding.value.page_element) {
          const dwid = [binding.value.page_name, binding.value.page_element].join('-')
          ele.dataset.dwid = dwid
          dwList[dwid] = {}
          dwList[dwid]['data'] = binding.value
          // threshold作为元素展示触发回调的比例阈值
          const threshold = binding.value.threshold === undefined ? 0 : binding.value.threshold
          dwList[dwid]['observer'] = new IntersectionObserver(callback, { threshold })
          dwList[dwid]['observer'].observe(ele)
        } else {
          console.warn('v-dw error:缺少参数page_name或page_element，作为上报数据的唯一标识，必传。示例：v-dw="{\'page_name\':\'name\', \'page_element\':\'element\'}"')
        }
      },
      unbind: function (ele, binding) {
        try {
          if (binding.value && binding.value.page_name && binding.value.page_element) {
            const dwid = [binding.value.page_name, binding.value.page_element].join('-')
            dwList[dwid]['observer'].unobserve(ele)
            delete dwList[dwid]
            ele.removeAttribute('dwid')
          }
        } catch (e) {
          console.warn('v-dw error:', e)
        }
      },
    })
  }
}

export default dw