import { isxxx } from '@/mixins/isxxx'
import { fixedNumber2 } from '@/filters/fixed-number'

const isPayByVoucher = isxxx.isWpsIos || isxxx.isWpsIosMp

export const voucher = {
  isPayByVoucher
}

export function refresh () {
  voucher.isPayByVoucher = isxxx.isWpsIos || isxxx.isWpsIosMp
}

const filters = {
  showPrice (price) {
    if (voucher.isPayByVoucher) {
      // 资源券向上取整
      price = Math.ceil(price)
    }
    return `${!voucher.isPayByVoucher ? '¥' : ''}${fixedNumber2(price, 2)}${voucher.isPayByVoucher ? '资源券' : ''}`
  }
}

// 资源券mixins
const voucherMixin = (arg = {}) => {
  const obj = {
    data () {
      return {
      // 是否使用资源券支付
        isPayByVoucher: voucher.isPayByVoucher
      }
    }
  }
  if (arg.filters) {
    obj.filters = {
      ...arg.filters.map(name => filters[name])
    }
  }
  return obj
}
export default voucherMixin
