import axios from "axios";
import os from "@edu-fe/os";

export async function isLoginInSystem() {
  try {
    const { data } = await getWpsSid();
    return !!data;
  } catch (e) {
    return false;
  }
}

export function getUserId() {
  axios.defaults.withCredentials = true;
  return axios.get("https://dynamicedu.wps.cn/API_V2/userid");
}

export function getWpsSid() {
  axios.defaults.withCredentials = true;
  return new Promise((resolve, reject) => {
    return axios
      .get("https://dynamicedu.wps.cn/API_V2/wpsSid")
      .then(result => {
        resolve(result.data);
      })
      .catch(e => {
        console.log("获取数据失败");
        reject(e);
      });
  });
}

// 网页浏览器 登录
const loginBrowse = backUrl => {
  const newUrl = encodeURIComponent(backUrl || window.location.href);
  const dynamiceduUrl = encodeURIComponent(
    `https://dynamicedu.wps.cn/API_V2/login?backUrl=${newUrl}`
  );
  const accountUrl = `https://account.wps.cn/login?hide=wx&cb=${dynamiceduUrl}`;
  window.open(accountUrl, "_self");
};

// 微信内登录
const loginWeixin = backUrl => {
  const newUrl = encodeURIComponent(backUrl || window.location.href);
  const apiUrl = `https://dynamicedu.wps.cn/API_V2/weixin/url/userinfo/v2?rd=${newUrl}`;
  window.location.href = apiUrl;
};

// 客户端内登录
const loginApp = callback => {
  return os.sdk.user.login().then(wpsSid => {
    callback && callback(wpsSid);
    return wpsSid;
  });
};

export function login(options = {}) {
  const { callback, backUrl = window.location.href } = options;
  switch (os.type) {
    case "app":
      return loginApp(callback);
    case "ali":
    case "browser":
      return loginBrowse(backUrl);
    case "weixin":
      return loginWeixin(backUrl);
    default:
      console.log("暂无可用的登录方式");
  }
}

export async function logout(callBack = () => window.location.reload()) {
  axios.defaults.withCredentials = true;
  const { data } = await axios.get("https://dynamicedu.wps.cn/API_V2/logout");
  if (data.data) {
    callBack();
  }
}
