import os from '@edu-fe/os'
import Login from '@edu-fe/login'
import axios from 'axios'
import * as get from '@ccforeverd/tool-get'

function getSid () {
  return Promise.resolve(JSON.parse(window.wps_javascript['getInformation']('')).wpsid)
}

function getToken () {
  return Promise.resolve(JSON.parse(window.wps_javascript['getInformation']('')).loginToken)
}

async function getWpsSid () {
  // 链接上挂的 wpsSid
  const data = get.query()
  if (data.wpsSid) {
    return data.wpsSid
  }

  // 正常获取 wpsSid
  switch (os.type) {
    case 'ali':
    case 'browser':
    case 'weixin':
      const { data: {
        code: code1,
        data: wpsSidFromAPI
      } } = await axios.get('https://dynamicedu.wps.cn/API_V2/wpsSid', {
        withCredentials: true
      })

      // 跨域失败, 未登录
      if (code1 !== 1) {
        return ''
      }

      const { data: {
        code: code2
      } } = await axios.get('https://dynamicedu.wps.cn/API_V2/user_info', {
        params: { wpsSid: wpsSidFromAPI },
        withCredentials: true
      })

      // 登录过期
      if (code2 !== 1) {
        return ''
      }

      return wpsSidFromAPI
    case 'app':
      if (os.system === 'ios') {
        return await Login.getWpsSid()
      } else {
        const wpsSidFromSDK = await getSid()
        if (!wpsSidFromSDK && window.wps_javascript['getInformation']) {
          const loginToken = await getToken()
          return loginToken
        }
        return wpsSidFromSDK
      }
    default:
      return ''
  }
}


// 登录
async function login (callback = () => {}) {
  if (window.wps_javascript) {
    window.wps_javascript.WpsEduAppLoginCallback = function () {
      const result = callback()
      if (result !== false) {
        window.location.reload()
      }
    }
    const result = await window.wps_javascript['startLocalActivity'](JSON.stringify({
      activity: 'com.wps.excellentclass.login.LoginActivity'
    }))
    return result
  }
  return Login.login()
}

export default {
  getWpsSid,
  login
}
