// 各种is

import os from '@edu-fe/os'
const merge = require('lodash.merge')

const getIsxxx = () => ({
  isWx: os.type === 'weixin',
  isApp: os.type === 'app',
  isWpsAndroid: os.type === 'app' && os.name === 'wps',
  isWpsAndroidMp: os.type === 'app' && os.name === 'wpsmp' && os.system === 'android', // wps安卓小程序
  isWpsIos: os.type === 'app' && os.name === 'wpsIos',
  isWpsIosMp: os.type === 'app' && os.name === 'wpsmp' && os.system === 'ios', // wps ios 小程序
  isAppAndroid: os.type === 'app' && os.name === 'android',
  isAppIos: os.type === 'app' && os.name === 'ios',
  isInWps: os.type === 'app' && (os.name === 'wps' || os.name === 'wpsIos' || os.name === 'wpsmp'),
  isInApp: os.type === 'app' && (os.name === 'android' || os.name === 'ios'),
  isInAllApps: os.type === 'app' || os.name === 'loan',
  isLoan: os.name === 'loan',
  isIos: !!(window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)),
  isAli: os.name === 'ali'
})

export const isxxx = getIsxxx()

export function refresh () {
  const newObj = getIsxxx()
  merge(isxxx, newObj)
}

export default {
  data () {
    return { ...isxxx }
  }
}
