import axios from 'axios'

const isProd = process.env.NODE_ENV === 'production'

/**
 *
 * 使用方法：
 * const wxJsSdk = new WxJsSdk([WxJsSdk.jsApi.SHARE_TO_FRIEND])
 * wxJsSdk.init().then(() => {
 *    wxJsSdk.setShareToFriend({ title, desc, link, imgUrl })
 * })
 *
 * <wx-open-launch-weapp>使用方法
 * vue template中使用
 * <wx-open-launch-weapp id="launch-btn" username="gh_xxxxxxxxxxx"  path="pages/login/login/main.html" @error="handleErrorFn" @launch="handleLaunchFn">
        <script type="text/wxtag-template">
            <style>.btn { display: flex;align-items: center; }</style>
            <div> <button class="wx-btn">跳转小程序</button>   </div>
        </script>
    </wx-open-launch-weapp>

  jsx写法中的使用方法
    <wx-open-launch-weapp
      id="launch-btn"
      username="gh_xxxxxxxx"
      path="pages/home/index?user=123&action=abc"
    >
      <template>
        <style>.btn { padding: 12px }</style>
        <button class="btn">打开小程序</button>
      </template>
    </wx-open-launch-weapp>

 * ignore 标签
 * Vue.config.ignoredElements = ['wx-open-launch-weapp']
 */
export default class WxJsSdk {
  isReady = false
  jsApiList = []
  openTagList = []
  // 必选，需要使用的JS接口列表
  static jsApi = {
    SHARE_TO_FRIEND: 'updateAppMessageShareData',
    SHARE_TO_FRIEND_ZONE: 'updateTimelineShareData'
  }
  // 可选
  static openTag = {
    LAUNCH_MINI_APP: 'wx-open-launch-weapp' // 微信版本要求为：7.0.12及以上。 系统版本要求为：iOS 10.3及以上、Android 5.0及以上。不然会闪退
  }
  constructor (jsApiList = [], openTagList = []) {
    // jsApiList一定要有
    if (jsApiList.length === 0) throw new Error('初始化参数必填，JS接口列表或开放标签列表不能同时为空')
    this.jsApiList = jsApiList
    this.openTagList = openTagList
  }

  async init () {
    return new Promise(async (resolve, reject) => {
      const { data } = await this.getWxConfig()
      if (!data || !data.data.appId) return Promise.reject(new Error('获取微信配置信息失败'))
      const criteria = {
        // debug: !isProd, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        jsApiList: this.jsApiList,
        openTagList: this.openTagList,
        ...data.data
      }
      const self = this
      window.wx.ready(function () {
        self.isReady = true
        resolve(true)
      })
      window.wx.error(function (res) {
        self.isReady = false
        reject(res)
      })
      window.wx.config(criteria)
    })
  }

  getWxConfig () {
    const url = this.getCurrentUrl()
    return axios.post('https://dynamicedu.wps.cn/API_V2/weixin/config', { url }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      // 表单序列化
      transformRequest: [(data) => {
        let ret = ''
        for (let it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        return ret
      }]
    })
  }

  getCurrentUrl () {
    let url = window.location.href
    if (url.includes('#')) {
      return url.split('#')[0]
    }
    return url
  }
}

// 微信sdk其他功能详见：https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html

/**
 * 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
 */
export function setShareToFriend ({ title, desc, link, imgUrl }) {
  if (!this.isReady) return
  return new Promise(resolve => {
    window.wx.updateAppMessageShareData({
      title, // 分享标题
      desc, // 分享描述
      link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl, // 分享图标
      success: function () {
        resolve('success')
      }
    })
  })
}
/**
 * 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
 */
export function setShareToFriendZone ({ title, link, imgUrl }) {
  if (!this.isReady) return
  return new Promise(resolve => {
    window.wx.updateAppMessageShareData({
      title, // 分享标题
      link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl, // 分享图标
      success: function () {
        resolve('success')
      }
    })
  })
}
