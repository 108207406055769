import api from '@edu-fe/api'

export default {
  name,
  getWxConfig (url) {
    return api.create('/weixin/config', {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: {
        url: url
      },
      transformRequest: [(data) => {
        let ret = ''

        for (let it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }

        return ret
      }]
    })
  },
  async _init (title, link, desc, imgUrl) {
    const res = await this.getWxConfig(this.serialize(window.location.href))()
    await this._initWx(res.data)
    await this._define(title, link, desc, imgUrl)
  },
  serialize (href) {
    return href.split('#')[0]
  },
  async _initWx (configs) {
    const {
      appId, // 必填，公众号的唯一标识
      timestamp, // 必填，生成签名的时间戳
      nonceStr, // 必填，生成签名的随机串
      signature// 必填，签名
    } = configs
    return new Promise(resolve => {
      console.log('wx init')

      window.wx.config({
        debug: false,
        appId,
        timestamp,
        nonceStr,
        signature,
        jsApiList: [ 'chooseWXPay', 'onMenuShareTimeline', 'onMenuShareAppMessage', 'getLocalImgData', 'chooseImage' ]
      })
      window.wx.error(function(res){
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        console.dir("errorMSG:"+res);
      });
      resolve()
      // window.wx.ready(() => resolve())
    })

  },
  async _define (title, link, desc, imgUrl) {
    const settings = {
      title: title,
      link: link,
      desc: desc,
      imgUrl: imgUrl
    }
    // this._defineTimeline(settings)
    // this._defineAppMessage(settings)

    window.wx.ready(() => {
      console.log('wx ready')
      this._defineTimeline(settings)
      this._defineAppMessage(settings)
    })
  },
  _defineTimeline (set) {
    // set.link = get.add(set.link, {
    console.log(window.wx)
    // })
    window.wx.onMenuShareTimeline(set)
  },

  _defineAppMessage (set) {
    // set.link = get.add(set.link, {
    //   from: 'edu_share_message'
    // })
    console.log('window wx')
    window.wx.onMenuShareAppMessage(set)
  }
}
